import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Layout from '../components/layout';
import QuestionCard from '../components/question-card';
import { shuffleArray } from '../utils/utils';

class RootIndex extends React.Component {
    render() {
        const links = get(this, 'props.data.allContentfulLinks.nodes') || [{}];
        const questions = get(this, 'props.data.allContentfulQuestion.edges');
        const questionPick = shuffleArray(questions);

        return (
            <Layout location={this.props.location} links={links}>
                <QuestionCard
                    questions={questionPick}
                    links={links}
                ></QuestionCard>
            </Layout>
        );
    }
}

export default RootIndex;

export const questionQuery = graphql`
    query QuestionQuery {
        allContentfulQuestion {
            edges {
                node {
                    title
                    correct {
                        raw
                    }
                    incorrectTwo {
                        raw
                    }
                    incorrectOne {
                        raw
                    }
                }
            }
        }
        allContentfulLinks {
            nodes {
                title
                link
                shouldHideIcon
            }
        }
    }
`;
