import React from 'react';
import * as styles from './buttonwrapper.module.css';

const ButtonWrapper = ({
    value,
    correctAnswer,
    callback,
    index,
    userAnswer,
    animation = true,
}) => {
    let letter = ['A', 'B', 'C'];
    let stateClass = '';
    if (userAnswer) {
        stateClass = 'faded';
        if (value === userAnswer) {
            if (correctAnswer) {
                stateClass = 'right';
                letter[index] = '✓';
            } else {
                stateClass = 'wrong';
                letter[index] = 'X';
            }
        }
    }

    return (
        <button
            className={
                styles.button +
                ` ${stateClass} ${userAnswer ? 'disabled' : styles.active} ${
                    animation ? ' animated' : ' notAnimated'
                }`
            }
            disabled={userAnswer ? true : false}
            value={value}
            onClick={() => callback(index)}
        >
            <span className={styles.letter + ` letter${letter[index]}`}>
                {' '}
                {letter[index]}
            </span>
            <span className="answer">{value}</span>
        </button>
    );
};

export default ButtonWrapper;
