import React, { useState } from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { CSSTransition } from 'react-transition-group';

import Container from './container';
import * as styles from './question-card.module.css';
import { orderArray } from '../utils/utils';
import ButtonWrapper from './buttonwrapper';
import TypedTitle from './typed-title';
import { getLink, shouldShow } from '../utils/utils';

const QuestionCard = ({ questions, links }) => {
    const [question, setQuestion] = useState();
    const [gameOver, setGameOver] = useState(false);
    const [userAnswer, setUserAnswer] = useState();
    const [first, setFirst] = useState(true);
    const [questionIndex, setQuestionIndex] = useState(0);

    if (!questions) return null;
    if (!Array.isArray(questions)) return null;
    !question && setQuestion(questions[questionIndex].node);

    const options = {
        renderMark: {
            [MARKS.BOLD]: (text) => <b className="font-bold">{text}</b>,
        },
        renderNode: {
            [BLOCKS.HEADING_2]: (node, children) => {
                return <h2>{children}</h2>;
            },
        },
    };
    let correct;
    let answers;
    let title;
    if (question) {
        correct = renderRichText(question?.correct, options)[0].props
            .children[0];
        answers = orderArray([
            renderRichText(question?.incorrectOne, options)[0].props
                .children[0],
            correct,
            renderRichText(question?.incorrectTwo, options)[0].props
                .children[0],
        ]);
        title = question.title;
    }

    const checkAnswer = (index) => {
        const answer = answers[index];
        const value =
            questionIndex < questions.length - 2 ? questionIndex + 1 : 0;
        setQuestionIndex(value);
        setUserAnswer(answer);
        setTimeout(() => {
            const isCorrect = correct === answer;
            setGameOver(isCorrect);
            if (!isCorrect) {
                setQuestion(questions[questionIndex + 1].node);
                setFirst(false);
                setUserAnswer();
            }
        }, 2000);
    };

    return (
        <Container>
            <CSSTransition in={gameOver} timeout={100} classNames="dialog">
                {!gameOver ? (
                    <div className={styles.questionCard}>
                        <TypedTitle fullTitle={title} isFirst={first} />
                        <div className={styles.meta}>
                            {answers &&
                                answers.map((answer, index) => (
                                    <ButtonWrapper
                                        key={index}
                                        index={index}
                                        value={answer}
                                        correctAnswer={correct === answer}
                                        callback={checkAnswer}
                                        userAnswer={userAnswer}
                                    />
                                ))}
                        </div>
                    </div>
                ) : (
                    <div className={styles.questionCard}>
                        <img
                            className={styles.mockup}
                            src={'/mock.png'}
                            alt="MockUp"
                        />
                        <div className={styles.meta + ` ${styles.sparkle}`}>
                            <p className={styles.paragraph}>
                                Play quizzes and earn tokens with the OGMI app
                            </p>
                            <ButtonWrapper
                                index={0}
                                value={userAnswer}
                                correctAnswer={true}
                                callback={() => {}}
                                userAnswer={userAnswer}
                                animation={false}
                            />
                            { shouldShow(links, 'Discord') && <a
                                href={getLink(links, 'Discord')}
                                className={styles.cta}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Join Discord 🌈
                            </a>} 
                        </div>
                    </div>
                )}
            </CSSTransition>
        </Container>
    );
};

export default QuestionCard;
