import React, { useState } from 'react';
import * as styles from './typed-title.module.css';

function TypedTitle({ fullTitle, isFirst }) {
    const [text, setText] = useState('');
    const [done, setDone] = useState(false);

    const title = fullTitle;
    const titleLength = title.length;
    let newText = '';
    const typeText = (text) => {
        if (text.length < titleLength) {
            const isSame = title.includes(text);
            newText = title.substring(0, isSame ? text.length + 1 : 0);
            setText(newText);
            setTimeout(() => typeText(newText), 120);
        } else {
            setDone(true);
        }
    };

    if (done && text !== fullTitle) {
        setText('');
        setDone(false);
        typeText(text);
    }

    React.useEffect(() => {
        if (isFirst) {
            setText(title);
            setDone(true);
        }
        if (text.length < titleLength && !isFirst) {
            typeText(text);
            return () => {
                setText('');
                setDone(false);
            };
        }
    }, []);

    return <h2 className={styles.title}>{text}</h2>;
}

export default TypedTitle;
